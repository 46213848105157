import React from 'react';
import Title from './Title';
import Popular from './PopularAcc'





class Chart extends React.Component {
	render() {
		return(		<React.Fragment>
			<Title>Today</Title>

			<Popular />

		</React.Fragment>)
	}
}


export default Chart
